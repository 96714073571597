import { useState, useEffect } from "react";
import i18n from "@helpers/i18n";

// HOOKS
import useResponsive from "@hooks/useResponsive";

const useCustomerModal = props => {
  const { partner, visible, advisor, client, isSession, onClose, updateCustomers, caseFilterContext, addCustomerModalOpen, toggleAddCustomerModal, setCustomerState } = props;
  const [currentView, setCurrentView] = useState("overview");
  const [isVisible, setIsVisible] = useState(visible);
  const isMobileOrTablet = useResponsive("down", "md");

  let viewItems = [...partner.modules];
  // Remove views which are not used on this menu (maybe add own config setting?)
  viewItems.forEach((item) => {
    if (
      item.key === "start" ||
      item.key === "poa" ||
      item.key === "kyc" ||
      item.key === "other"
    ) {
      viewItems = viewItems.filter((i) => i.key !== item.key);
    }
  });

  // Add menu specific options
  viewItems.unshift({
    key: "overview"
  });

  const views = viewItems.map(item => {
    return {
      name: item.key,
      label: i18n("dashboard_selector", item.key)
    };
  });
  
  const handleViewChange = (e, newValue) => {
    if (caseFilterContext) caseFilterContext.setCaseFilter(null); // reset case filter (force using default tab)
    setCurrentView(newValue);
  };

  const handleClose = () => {
    setIsVisible(false);
    if (onClose) {
      onClose();      
      document.documentElement.style.overflow = "auto";
    }
  };

  // check if props changed
  useEffect(() => {
    setIsVisible(visible);
  }, [visible])
  

  return {
    isMobileOrTablet,
    isVisible,
    partner,
    advisor,
    client,
    views,
    currentView,
    isSession,
    handleClose,
    handleViewChange,
    updateCustomers,
    caseFilterContext,
    addCustomerModalOpen,
    toggleAddCustomerModal,
    setCustomerState
  };
};

export default useCustomerModal;