import { useState, useEffect } from "react";

// HOOKS
import useResponsive from "@hooks/useResponsive";

const useCustomersTable = props => {
  const isMobileOrTablet = useResponsive("down", "md");
  const { inline, showHeader, width, partner, advisor, customers, setCustomerState, caseFilterContext, nextStepOptions } = props;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [copyList, setCopyList] = useState([]);
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [addCustomerModalOpen, setAddCustomerModalOpen] = useState(false);
  const [newCustomerAdded, setNewCustomerAdded] = useState(null);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const updateCustomers = customer => {
    // find existing index
    let index = customers.findIndex(c => 
      c.client_id && customer.clientId && c.client_id === customer.clientId || 
      c.clientId && customer.clientId && c.clientId === customer.clientId
    );
    const isNewCustomer = index === -1;
    // if not found, add to end (new customer)
    if (isNewCustomer) {
      index = customers.length;
      setNewCustomerAdded(customer);
    }
    // update customers with new customer record
    customers[index] = customer;
    // sort alphabetically
    customers.sort((a, b) =>
      a.name.localeCompare(b.name, "sv", { sensitivity: "base" })
    );

    // if a customer is selected, update it (edit from customer modal)
    if (selectedCustomer) setSelectedCustomer(customer);

    return setCopyList(customers);
  };

  const requestSearch = searched => {
    if (page > 0) setPage(0);
    const searchString = searched.toLowerCase();
    setCopyList(
      customers.filter(
        customer =>
          (customer.first_name + " " + customer.last_name)
            .toLowerCase()
            .includes(searchString) ||
          customer.id?.toLowerCase().includes(searchString) ||
          customer.name?.toLowerCase().includes(searchString)
      )
    );
  };

  const toggleAddCustomerModal = action => {
    if (!customerModalOpen && (action === "add" || addCustomerModalOpen)) setSelectedCustomer(null);

    if (addCustomerModalOpen) { // disable/enable background content scrolling while modal is open    
      document.documentElement.style.overflow = "auto";
    } else {
      document.documentElement.style.overflow = "hidden";
    }

    setAddCustomerModalOpen(!addCustomerModalOpen);
  };

  const handleClientClick = client => async e => {
    e.preventDefault();
    setCustomerState({
      isCompany: false,
      ssnOrg: client.id ? (client.id).replace("-", "") : "",
      isNewCase: null,
      standaloneApplicationsChecked: [],
      needsNewDocumentation: false,
      kycCaseId: null,
      kycInfoLoading: false,
      overrideNewDocumentation: false,
      casesLoading: false,
      cases: null,
      selectedCaseId: null,
      selectedCaseIndex: -1,
      customerModalIsOpen: true,
    }, () =>
		{
      setNewCustomerAdded(null);
      setCustomerModalOpen(true);
      setSelectedCustomer(client);
      document.documentElement.style.overflow = "hidden";
		});
  };

  const handleClientModalClose = () => {
    setCustomerState({
      isCompany: false,
      ssnOrg: "",
      customerModalIsOpen: false
    }, () =>
		{
      setCustomerModalOpen(false);
      setSelectedCustomer(null);
		});
  };

  useEffect(() => {
    if (customers) setCopyList(customers);
  },[customers]);

  return {
    inline,
    showHeader,
    width,
    isMobileOrTablet,
    partner,
    advisor,
    copyList,
    page,
    setPage,
    rowsPerPage,
    customerModalOpen,
    selectedCustomer,
    setSelectedCustomer,
    setRowsPerPage,
    handleChangePage,
    requestSearch,
    toggleAddCustomerModal,
    handleClientClick,
    handleClientModalClose,
    updateCustomers,
    caseFilterContext,
    nextStepOptions,
    setCustomerState,
    addCustomerModalOpen,
    newCustomerAdded
  };
};

export default useCustomersTable;